<!-- 高清变焦相机P20 -->
<template>
  <div class="cpt-MMC_Gimbal_ZT60R" :style="containerStyle">
    <div v-interact class="hd">
      <div class="left ml8">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="title">高清变焦相机P20</div>
      </div>
      <div class="close" @click="close">关闭</div>
    </div>
    <div class="bd">
      <div class="form-wrap">
        <div class="form-item">
          <div class="label-box">控制速度:</div>
          <div class="input-box">
            <div class="speedC">
              <div
                v-for="(item, index) in new Array(5).fill(1)"
                :key="index"
                class="speed"
                :style="
                  control_speed == index + 1
                    ? 'background: #FFFFFF;color: #333333;'
                    : ''
                "
                @click="control_speed = index + 1"
              >
                {{ index + 1 }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">云台模式</div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-long">
                <div
                  class="mono-left mono_box"
                  :class="{ active: holderModelDomV2 == 0 }"
                  @click="handle_change_ptz_mode(0)"
                />
                <div
                  class="mono-middle mono_box"
                  :class="{ active: holderModelDomV2 == 1 }"
                  @click="handle_change_ptz_mode(1)"
                />
                <div
                  class="mono-right mono_box"
                  :class="{ active: holderModelDomV2 == 2 }"
                  @click="handle_change_ptz_mode(2)"
                />
              </div>
              <div class="text">
                <div class="text-left">锁定</div>
                <div class="text-right">跟随</div>
                <div class="text-right">回中</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-item mt10">
          <div class="label-box">HDR</div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-long">
                <div
                  class="mono-left mono_box"
                  :class="{ active: HDRmodel == 0 }"
                  @click="handle_change_HDR(0)"
                />
                <div
                  class="mono-middle mono_box"
                  :class="{ active: HDRmodel == 1 }"
                  @click="handle_change_HDR(1)"
                />
                <div
                  class="mono-right mono_box"
                  :class="{ active: HDRmodel == 2 }"
                  @click="handle_change_HDR(2)"
                />
              </div>
              <div class="text">
                <div class="text-left">关闭</div>
                <div class="text-right">HDR</div>
                <div class="text-right">HDR+</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">相机模式:</div>
          <div class="input-box">
            <el-radio-group
              v-model="cameraModel"
              @change="handle_change_camera_model"
            >
              <el-radio
                v-for="item in drd.cameraModel"
                :key="item.id"
                size="mini"
                :label="item.id"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="item-group jcsb ml100 w100">
          <div class="item-box" @click="handle_take_photo">
            <el-tooltip
              class="item"
              effect="dark"
              content="拍照"
              placement="top"
            >
              <div class="icon-box cp">
                <!-- <span class="iconfont icon-paizhao1"></span> -->
                <img src="~@/assets/images/mount/photo.png" alt />
              </div>
            </el-tooltip>
          </div>
          <div class="item-box" @click="handle_record">
            <el-tooltip
              class="item"
              effect="dark"
              content="录像"
              placement="top"
            >
              <div class="icon-box cp">
                <!-- <span class="iconfont icon-dandianshipin"></span> -->
                <img
                  v-if="!record"
                  src="~@/assets/images/mount/record.png"
                  alt
                />
                <img v-else src="~@/assets/images/mount/stop.png" alt />
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            俯&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;仰
          </div>
          <div class="input-box">
            <el-input
              v-model.number="pitch"
              size="mini"
              max="30"
              min="-90"
              value="0"
              onblur="if(value>30)value=30;if(value<-90)value=-90;if(!value)value=0"
              placeholder="请输入俯仰"
            />
            <el-button size="mini" type="primary" @click="handle_change_pitch">
              <span class="cf">设置</span>
            </el-button>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            航&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;向
          </div>
          <div class="input-box">
            <el-input
              v-model.number="yaw"
              max="145"
              min="-145"
              onblur="if(value>145)value=145;if(value<-145)value=-145;if(!value)value=0"
              size="mini"
              placeholder="请输入航向"
            />
            <el-button size="mini" type="primary" @click="handle_change_yaw">
              <span class="cf">设置</span>
            </el-button>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            变&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;焦
          </div>
          <div class="input-box">
            <div
              class="mr10 cur"
              @mousedown="handle_zoom_reduce()"
              @mouseup="stopChange"
              @mouseout="stopChange"
            >
              <span class="iconfont icon-shanchu3" />
            </div>
            <div
              class="ml10 cur"
              @mousedown="handle_zoom_plus()"
              @mouseup="stopChange"
              @mouseout="stopChange"
            >
              <span class="iconfont icon-tianjia1" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            变&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;倍
          </div>
          <div class="input-box">
            <div
              class="mr10 cur"
              @mousedown="zoom(1)"
              @mouseup="zoom(0)"
              @mouseout="zoom(0)"
            >
              <span class="iconfont icon-shanchu3" />
            </div>
            <div
              class="ml10 cur"
              @mousedown="zoom(2)"
              @mouseup="zoom(0)"
              @mouseout="zoom(0)"
            >
              <span class="iconfont icon-tianjia1" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">夜间模式</div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-long">
                <div
                  class="mono-left mono_box"
                  :class="{ active: holder_night_value == 0 }"
                  @click="handle_change_night_mode(0)"
                />
                <div
                  class="mono-middle mono_box"
                  :class="{ active: holder_night_value == 1 }"
                  @click="handle_change_night_mode(1)"
                />
                <div
                  class="mono-right mono_box"
                  :class="{ active: holder_night_value == 2 }"
                  @click="handle_change_night_mode(2)"
                />
              </div>
              <div class="text">
                <div class="text-left">自动</div>
                <div class="text-right">黑白</div>
                <div class="text-right">彩色</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">防抖:</div>
          <div class="input-box">
            <el-radio-group v-model="fd_mode" @change="handle_change_fd_mode">
              <el-radio
                v-for="item in drd.dj_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">透雾:</div>
          <div class="input-box">
            <el-radio-group v-model="tw_mode" @change="handle_change_tw_mode">
              <el-radio
                v-for="item in drd.dj_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">OSD:</div>
          <div class="input-box">
            <el-radio-group
              v-model="osd_model"
              @change="handle_change_osd_model"
            >
              <el-radio
                v-for="item in drd.dj_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">低延:</div>
          <div class="input-box">
            <el-radio-group v-model="dy_model" @change="handle_change_dy_model">
              <el-radio
                v-for="item in drd.dj_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
              >
                {{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <div class="rocker-wrap">
        <div ref="rocker" class="rocker">
          <div
            class="shangUp"
            @mousedown="fangxiang('up')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          />
          <div
            class="xaiUp"
            @mousedown="fangxiang('down')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          />
          <div
            class="zuoUp"
            @mousedown="fangxiang('left')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          />
          <div
            class="youUp"
            @mousedown="fangxiang('right')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          />
        </div>
        <el-button class="ml60 mt30" @click="pidBtn">PID设置</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { MMC_Gimbal_P20 } from "./js/index.js";
let targetPoint = null;
export default {
  props: {
    containerStyle: {
      type: Object,
      default: () => ({}),
    },
    device: {
      type: Object,
      default: () => ({}),
    },
    selected_mount: {
      type: Object,
      default: () => ({}),
    },
    moveType: {
      type: String,
      default: () => "",
    },
    keyFlag: {
      type: Boolean,
      default: () => false,
    },
    payload_data: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      // 速度
      control_speed: 3,
      // 低延
      dy_model: 0,
      // 防抖
      fd_mode: 0,
      // osd
      osd_model: 0,
      // hdr
      HDRmodel: 0,
      // 相机模式
      cameraModel: 0,
      // 画中画
      frameModel: 0,
      // 选择模式
      control_mode: 3,
      // // 点击
      holderModelDomV1: 1,
      // 云台模式
      holderModelDomV2: 1,
      // 跟踪控制
      dj_mode: 0,
      // 俯仰
      pitch: 0,
      // 航向
      yaw: 0,
      // 夜间模式
      holder_night_value: 0,
      zd_mode: 0,
      // 电子变倍
      // dz_mode: 0,
      // 透雾
      tw_mode: 0,
      timer: null,
      value: 25,
      value2: 25,
      ptz_mode: 1,
      record: false,
      radio: 1,
      opacity: {},
      // gcs_status: "",
      jgcj: 0,
      drd: {
        dj_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "打开" },
        ],
        cameraModel: [
          { id: 0, label: "正常" },
          { id: 1, label: "夜间" },
        ],
        ptz_mode: [
          { id: 0, label: "锁定" },
          { id: 1, label: "跟随" },
          { id: 2, label: "回中" },
        ],
      },
    };
  },
  computed: {
    stream() {
      return this.$store.state.fckernel.stream;
    },
  },
  watch: {
    payload_data: {
      handler(value) {
        // this.resolve_payload(value);
      },
      deep: true,
    },
  },
  beforeDestroy() {
    if (targetPoint) {
      window.viewer.entities.remove(targetPoint);
      targetPoint = null;
    }
    this.$store.commit("device/SET_MOVE_DATA", {
      ...this.device,
      mountStatus: 0,
    });
  },
  methods: {
    pidBtn() {
      this.commit_directive([165, 13, 5, 101, 116, 245]);
    },
    // 相机模式
    handle_change_camera_model(model) {
      if (model == 1) {
        this.commit_directive([165, 31, 3, 1, 9]);
      } else {
        this.commit_directive([165, 31, 3, 0, 56]);
      }
    },
    // 云台模式
    handle_change_ptz_mode(value) {
      this.holderModelDomV2 = value;
      const bufferList = {
        0: [165, 8, 3, 0, 127],
        1: [165, 8, 3, 1, 78],
        2: [165, 8, 3, 2, 29],
      };
      const buffer = bufferList[value];
      this.commit_directive(buffer);
    },
    // HDR
    handle_change_HDR(value) {
      this.HDRmodel = value;
      const bufferList = {
        0: [165, 9, 3, 0, 57],
        1: [165, 9, 3, 1, 8],
        2: [165, 9, 3, 2, 91],
      };
      const buffer = bufferList[value];
      this.commit_directive(buffer);
    },
    // 指点移动
    handle_change_zd_mode(id) {
      this.$store.commit("device/SET_MOVE_DATA", {
        ...this.device,
        mountStatus: id,
        mountId: this.selected_mount.mountId,
        moveType: this.moveType,
        name: this.selected_mount.name,
      });
    },
    handle_change_pitch() {
      if (this.pitch > 200) {
        this.pitch = 200;
      }
      if (this.pitch) {
        const buffer = MMC_Gimbal_P20.change_pitch_angle(this.pitch);
        this.commit_directive(buffer);
      }
    },
    handle_change_yaw() {
      const buffer = MMC_Gimbal_P20.change_yaw_angle(this.yaw);
      this.commit_directive(buffer);
    },
    zoom(value) {
      let buffer = null;
      if (value == 0) {
        buffer = [165, 4, 3, 1, 53];
      } else if (value == 1) {
        buffer = [165, 4, 3, 0, 4];
      } else if (value == 2) {
        buffer = [165, 4, 3, 2, 102];
      }
      this.commit_directive(buffer);
    },
    // 变倍
    // handle_change_Zoom() {
    //   const buffer = MMC_Gimbal_ZT60R.zoomTo(this.zoom);
    //   this.commit_directive(buffer);
    // },
    // 变焦 -
    handle_zoom_reduce() {
      this.commit_directive([165, 17, 4, 0, 0, 138]);
    },
    // 变焦 +
    handle_zoom_plus() {
      this.commit_directive([165, 17, 4, 2, 0, 83]);
    },
    stopChange() {
      this.value = 25;
      this.value2 = 25;
      this.commit_directive([165, 17, 4, 1, 0, 126]);
    },
    handle_zoom(mode) {
      const list = {
        0: [165, 79, 10, 85, 170, 220, 5, 28, 0, 121, 96, 153],
        1: [165, 79, 10, 85, 170, 220, 5, 28, 0, 123, 98, 34],
        2: [165, 79, 10, 85, 170, 220, 5, 28, 0, 124, 101, 27],
        3: [165, 79, 10, 85, 170, 220, 5, 28, 0, 122, 99, 231],
      };
      this.commit_directive(list[this.frameModel]);
    },
    // 夜间模式
    handle_change_night_mode(num) {
      this.holder_night_value = num;
      let buffer = null;
      if (num == 0) {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 81, 3, 255, 223, 134,
        ];
      } else if (num == 1) {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 1, 2, 255, 142, 87,
        ];
      } else {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 1, 3, 255, 143, 32,
        ];
      }
      this.commit_directive(buffer);
    },
    // 透雾
    handle_change_tw_mode(num) {
      let buffer = null;
      if (num == 0) {
        buffer = [165, 32, 3, 1, 101];
      } else {
        buffer = [165, 32, 3, 0, 84];
      }
      this.commit_directive(buffer);
    },
    handle_change_osd_model(num) {
      let buffer = null;
      if (num == 0) {
        buffer = [165, 22, 3, 0, 44];
      } else {
        buffer = [165, 22, 3, 1, 29];
      }
      this.commit_directive(buffer);
    },
    handle_change_dy_model(num) {
      let buffer = null;
      if (num == 0) {
        buffer = [165, 43, 3, 0, 204];
      } else {
        buffer = [165, 43, 3, 1, 253];
      }
      this.commit_directive(buffer);
    },
    handle_change_fd_mode(num) {
      let buffer = null;
      if (num == 0) {
        buffer = [165, 42, 3, 0, 138];
      } else {
        buffer = [165, 42, 3, 1, 187];
      }
      this.commit_directive(buffer);
    },
    close() {
      if (this.record) {
        this.$message.info("请结束录像后再关闭！");
      } else {
        this.$emit("close");
      }
    },
    handle_take_photo() {
      // let { position } = this;
      // let buffer = MMC_Gimbal_Z30Pro.take_photo(position || {});
      if (!this.keyFlag) return this.$message.error("拍照失败，NX通信异常！");
      const streamData = {
        data: {
          data: {
            videoID: 1,
          },
        },
        type: 528,
      };
      if (this.stream == "QingLiu") {
        streamData.data.messageID = 1016;
      } else {
        streamData.data.messageID = 1007;
      }
      this.$emit("take_photo", streamData);
    },
    handle_record() {
      if (!this.keyFlag) return this.$message.error("录像失败，NX通信异常！");
      this.record = !this.record;
      const streamData = {
        data: {
          data: {
            videoID: 1,
          },
        },
        type: 528,
      };
      if (this.stream == "QingLiu") {
        streamData.data.messageID = 1017;
        streamData.data.data.status = this.record;
      } else {
        streamData.data.messageID = 1006;
        streamData.data.data.recordControl = this.record;
      }
      this.$emit("record", streamData);

      // let buffer = MMC_Gimbal_Z30Pro.record(this.record);
      // this.commit_directive(buffer);
    },

    onChangezoom(e) {
      console.log(e, "e");
      if (e < 25) {
        const arr = [165, 4, 3, 0, 4];
        this.commit_directive(arr);
        // this.handle_zoom(0);
      }
      if (e > 25) {
        const arr = [165, 4, 3, 2, 102];
        this.commit_directive(arr);
      }
      if (e == 25) {
        this.handle_zoom(1);
      }
      this.value2 = e;
    },

    commit_directive(buffer) {
      this.$emit("directive", buffer);
    },
    fangxiang(type) {
      let buffer = null;
      switch (type) {
        case "up":
          buffer = MMC_Gimbal_P20.gimbal_pitch_ctrl(1, this.control_speed);
          this.commit_directive(buffer);

          break;
        case "left":
          buffer = MMC_Gimbal_P20.gimbal_yaw_ctrl(-1, this.control_speed);
          this.commit_directive(buffer);
          break;
        case "right":
          buffer = MMC_Gimbal_P20.gimbal_yaw_ctrl(1, this.control_speed);
          this.commit_directive(buffer);

          break;
        case "down":
          buffer = MMC_Gimbal_P20.gimbal_pitch_ctrl(-1, this.control_speed);
          this.commit_directive(buffer);
      }
    },
    stopfxFn() {
      const buffer = [165, 6, 3, 0, 136];
      this.commit_directive(buffer);
      this.commit_directive([165, 7, 3, 0, 206]);
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-MMC_Gimbal_ZT60R {
  width: 470px;
  height: 290px;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  background-size: 100% 100%;
  box-sizing: border-box;
  // padding: 10px 20px;
  position: absolute;
  right: 0px;

  .hd {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;

    .left {
      display: flex;
      align-items: center;

      .title {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }

  .bd {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    overflow: auto;
    height: 230px;

    .form-wrap {
      flex-shrink: 0;

      .form-item {
        display: flex;
        align-items: center;
        height: 40px;

        .label-box {
          color: #cbd0eb;
          width: 75px;
        }

        .input-box {
          display: flex;
          align-items: center;

          .mono {
            display: flex;
            flex-direction: column;

            .mono-long {
              display: flex;
              justify-content: space-between;
              width: 168px;
              height: 17px;
              background: url("~@/assets/images/mount_long.png") no-repeat;
              background-size: 100%;
              margin-bottom: 5px;

              .mono_box {
                width: 28px;
                height: 13px;
                border-radius: 3px;
                margin-top: 2px;
                cursor: pointer;
              }

              .active {
                background: url("~@/assets/images/mount/mount_control.png")
                  no-repeat !important;
                background-size: 100% 100%;
              }
            }

            .text {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #ffffff;
              display: flex;
              justify-content: space-between;
              font-size: 14px;
            }
          }
        }
      }
    }

    .rocker-wrap {
      position: absolute;
      right: 30px;
      // flex: 1;

      .title-box {
        .title {
          color: #cbd0eb;
        }
      }

      .rocker {
        // margin: 10px auto 0;
        width: 150px;
        height: 150px;
        position: relative;
        margin: 0 auto;
        background: center url("~@/assets/images/accident/caozuo.png") no-repeat;
        background-size: 100% 100%;

        .shangUp {
          position: absolute;
          left: 37%;
          top: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .xaiUp {
          position: absolute;
          left: 37%;
          bottom: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .zuoUp {
          position: absolute;
          left: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .youUp {
          position: absolute;
          right: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
      }
    }
  }
}
::v-deep.el-slider__bar {
  background: #fff;
}

.speedC {
  width: 203px;
  height: 24px;
  display: flex;
  background: rgba(13, 34, 79, 0.65);
  border: 1px solid #004fff;
  border-radius: 12px;

  .speed {
    width: 41px;
    // background: #FFFFFF;
    color: #ffffff;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
  }
}

// 变焦功能
.iconfont {
  color: #004fff;
}

// 变焦功能
::v-deep .el-slider__runway {
  visibility: hidden;
}
::v-deep .el-radio {
  color: #fff;
}
.cur {
  cursor: pointer;
}
</style>
